import React, { useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { getPortfolioDetailsFn } from "../context/endpoints";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import ContactForm from "../components/contact-form";
import BuildSection from "../components/build-section";
import { convertToCurrency } from "../hooks/currency";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import DescriptionReadMore from "../components/description-read-more";

const PortfolioDetails = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const navigate = useNavigate();
  // This code to go back
  const goBack = () => navigate(-1);

  const myRef = useRef(null);
  // Scroll to contact form
  const ScrollToContactForm = () => myRef.current.scrollIntoView();

  // This code to fatch the property details by id
  const { isPending, error, data } = useQuery({
    queryKey: ["PortfolioDetailsData", id],
    queryFn: () => getPortfolioDetailsFn(id),
  });
  return (
    <>
      <Header />
      {isPending ? (
        <div className="container-secondary">
          <div className="property-details-container">
            <div className="details-container">
              <div className="details-left-side">
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "65px", marginBottom: "5px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px", marginBottom: "30px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px", marginBottom: "25px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
                <Skeleton variant="text" width="100%">
                  <div style={{ paddingTop: "23px" }} />
                </Skeleton>
              </div>
              <div className="details-right-side">
                <div className="details-right-side-main-image">
                  <Skeleton variant="rectangular" width="100%">
                    <div style={{ paddingTop: "597px" }} />
                  </Skeleton>
                </div>
                <div
                  className="details-description-container"
                  style={{ display: "block" }}
                >
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                </div>
                <div className="details-right-side-images">
                  {Array.from({ length: 2 }).map((_, index) => (
                    <Box key={index}>
                      <Skeleton variant="rectangular" width="100%">
                        <div style={{ paddingTop: "313px" }} />
                      </Skeleton>
                    </Box>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : error ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : (
        <>
          <Helmet>
            <title>{`${data.project_title} - BlackBrick`}</title>
            <meta name="description" content={data.description} />
            <meta name="keywords" content={data.keyword} />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://blackbrickproperty.com/portfolio/${data._id}`}
            />
            <meta
              property="og:title"
              content={`${data.project_title} - BlackBrick`}
            />
            <meta property="og:description" content={data.description} />
            <meta property="og:image" content={data.images[0]} />
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content={`https://blackbrickproperty.com/portfolio/${data._id}`}
            />
            <meta
              property="twitter:title"
              content={`${data.project_title} - BlackBrick`}
            />
            <meta property="twitter:description" content={data.description} />
            <meta property="twitter:image" content={data.images[0]} />
          </Helmet>
          <div className="backtoallresults">
            <h2 onClick={goBack}>
              <span>&#60;</span>back to all results
            </h2>
          </div>
          <div className="container-secondary">
            <div className="property-details-container">
              <div className="details-container">
                <div className="details-left-side">
                  <h1>{data.project_title}</h1>
                  <p>{data.project_subject}</p>
                  <h2>DETAILS:</h2>
                  {data.project_budget !== 0 && (
                    <h3>
                      Project Value&nbsp;&nbsp;&nbsp;AED{" "}
                      {convertToCurrency(data.project_budget)}
                    </h3>
                  )}
                  <h4>{data.bedrooms} Bedrooms</h4>
                  <h4>{data.bathrooms} Bathrooms</h4>
                  <h4>{convertToCurrency(data.bua)} BUA</h4>
                  <h4>{convertToCurrency(data.plot_area)} Plot Area</h4>
                  {data.project_duration !== 0 && (
                    <h4>Project Duration {data.project_duration} Months</h4>
                  )}
                  <h5>{data.location}</h5>
                </div>
                <div className="details-right-side">
                  <div className="details-right-side-main-image">
                    {data.images.length > 0 &&
                      data.images
                        .slice(0, 1)
                        .map((item, index) => (
                          <img key={index} src={item} alt="property images" />
                        ))}
                    {data.images.length > 1 ? (
                      <Link to={`/portfolio/${data._id}/gallery`}>
                        <button>more images</button>
                      </Link>
                    ) : null}
                  </div>
                  <div className="details-description-container">
                    <div className="details-description">
                      <DescriptionReadMore>
                        {data.description}
                      </DescriptionReadMore>
                    </div>
                    <div className="details-description-buttons">
                      <button onClick={ScrollToContactForm}>
                        request details
                      </button>
                      <button onClick={ScrollToContactForm}>inquiry</button>
                    </div>
                  </div>
                  <div className="details-right-side-images">
                    {data.images.length > 1 &&
                      data.images.slice(1, 3).map((item, index) => (
                        <Link key={index} to={`/portfolio/${data._id}/gallery`}>
                          <img src={item} alt="property images" />
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={myRef}>
            <ContactForm
              formTitle={`${data.project_title}?`}
              formSubtitle={"Interested in"}
            />
          </div>
        </>
      )}
      <div className="build-section-secondary-color">
        <BuildSection />
      </div>
      <Footer />
    </>
  );
};

export default PortfolioDetails;
