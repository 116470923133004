import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";

const bbTermsAndCondtions = () => {
    return (
        <>
            <Helmet>
                <title> Terms and Condition</title>
            </Helmet>
            
            <Header />

            <Footer />
        </>
    );
};

export default bbTermsAndCondtions;
