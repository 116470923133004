import React,{useState} from "react";
import Header from "../components/header";
import Testimonial from "../components/testimonial";
import Footer from "../components/footer";
import ContactForm from "../components/contact-form";
import GoogleReviews from "../components/google-reviews";

import { useQuery } from "@tanstack/react-query";
import { getSettingsFn } from "../context/endpoints";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";
import RegisterForm from "../components/register";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {

  // This code is using the useQuery hook from a library like React Query. It is fetching data using the getSettingsFn function and storing the result in the isPending, error, and data variables. The queryKey is used to identify the query.
  const { isPending, error, data } = useQuery({
    queryKey: ["settingsData"],
    queryFn: () => getSettingsFn(),
  });

  // This code snippet creates an empty object called websitesettings, and if isPending is false and there is no error, it uses Object.assign to merge the contents of all the objects in the data array into the websitesettings object.
  let websitesettings = {};
  if (!isPending && !error) {
    websitesettings = Object.assign({}, ...data);
  }

  const [showPopup, setShowPopup] = useState(false);

  const handleEnquireClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSubmitStatus = (success, result) => {
    if (success) {
      console.log('Form submitted successfully:', result);
    } else {
      toast.error('Failed to submit enquiry. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log('Form submission failed.');
    }
  };
  return (
    <>
      <Helmet>
        <title>Contact - BlackBrick</title>
        <meta
          name="description"
          content="If you want to discuss a listing, talk through a development opportunity or enquire about anything else, get in touch using the links below"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://blackbrickproperty.com/contact"
        />
        <meta property="og:title" content="Contact - BlackBrick" />
        <meta
          property="og:description"
          content="If you want to discuss a listing, talk through a development opportunity or enquire about anything else, get in touch using the links below"
        />
        <meta property="og:image" content="/logo512.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://blackbrickproperty.com/contact"
        />
        <meta property="twitter:title" content="Contact - BlackBrick" />
        <meta
          property="twitter:description"
          content="If you want to discuss a listing, talk through a development opportunity or enquire about anything else, get in touch using the links below"
        />
        <meta property="twitter:image" content="/logo512.png" />
      </Helmet>
      <div className="contact-container">
        <Header />
        <div className="contact-texts">
          <h1>Get in touch</h1>
          <h2>
            If you want to discuss a listing, talk through a development
            opportunity or enquire about anything else, get in touch using the
            links below
          </h2>
        </div>
        {isPending ? null : error ? (
          <ErrorMessage>{error.message}</ErrorMessage>
        ) : (
          <div className="contact-info">
            <h1>
              EMAIL:{" "}
              <a
                href={`mailto:${websitesettings.email}`}
                rel="noopener noreferrer"
              >
                {websitesettings.email}
              </a>
            </h1>
            <h1>
              PHONE:{" "}
              <a
                href={`tel:${websitesettings.phone}`}
                rel="noopener noreferrer"
              >
                {websitesettings.phone}
              </a>
            </h1>
            <h1>
              ADDRESS:{" "}
              <a
                href="https://www.google.com/maps?q=25.0965095,55.1696920"
                rel="noopener noreferrer"
                target="_blank"
              >
                Zabeel House, Unit 1114, Onyx Tower 2, Dubai, UAE
              </a>
            </h1>
          </div>
        )}
        <div className="contact-icons">
          <a
            href="https://www.facebook.com/BlackBrick.ae"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa-brands fa-facebook-f"></i>
          </a>
          <a
            href="https://instagram.com/blackbrickproperty"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a
            href="https://ae.linkedin.com/company/blackbrickproperty"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa-brands fa-linkedin-in"></i>
          </a>
        </div>
        <div className="contact-register-button">
          <button onClick={handleEnquireClick}>Enquire Now</button>
        </div>
        {showPopup && (
          <RegisterForm onClose={handleClosePopup} onSubmitStatus ={handleSubmitStatus} />
        )}
      </div>
      <Testimonial />
      <GoogleReviews placeId={"ChIJ8SZPNC5rXz4RAGkOeFRCUGo"} apiKey={"AIzaSyCr8gUuxHjGaun4OPFzy6xyXGYVXAKfDBI"} />

      <ContactForm formTitle={""} formSubtitle={"Contact us"} />
      <Footer />
    </>
  );
};

export default Contact;
