import React,{useState} from "react";
import Header from "../components/header";
import Testimonial from "../components/testimonial";
import Footer from "../components/footer";
import { useQuery } from "@tanstack/react-query";
import { getSettingsFn, getAgentProfile } from "../context/endpoints";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";
import { useLocation } from 'react-router-dom';
import  ConnectPopup from "./../components/connect-popup"
import AgentReadMore from "../components/home/agentReadMore";
import FeaturedListingsForSale from "../components/home/featured-listings-for-sale";
import FeaturedListingsForRent from "../components/home/featured-listings-for-rent";

const AgentProfile = () => {
  
  const location = useLocation();
  const AgentID = location.pathname.split("/")[2];
  
  const [isConnectPopupOpen, setIsConnectPopupOpen] = useState(false);

  const togglePopup = () => {

    if (window.gtag) {
      window.gtag('event', 'agent_connect', {
        'send_to': 'G-F4J8CJGY6Y'
      });
    }

    setIsConnectPopupOpen(!isConnectPopupOpen);
  };

    // This code is using the useQuery hook from a library like React Query. It is fetching data using the getSettingsFn function and storing the result in the isPending, error, and data variables. The queryKey is used to identify the query.
  const { isPending, error, data } =  useQuery({
        queryKey: ["AgentProfile"],
        queryFn: () => getAgentProfile(AgentID),
  });

    // This code is using the useQuery hook from a library like React Query. It is fetching data using the websitesettingsData function and storing the result in the isPending, error, and data variables. The queryKey is used to identify the query.
  const websitesettingsData = useQuery({
    queryKey: ["settingsData"],
    queryFn: () => getSettingsFn(),
  });

  const whatsappContact =(contactNumber)=> {
    if (window.gtag) {
      window.gtag('event', 'agent_whatsapp', {
        'send_to': 'G-F4J8CJGY6Y'
      });
    }
    // Assuming the contact number is in international format without the plus sign
    const whatsappUrl = `https://wa.me/+${contactNumber}`;
    window.open(whatsappUrl, '_blank');

  }
  // This code snippet creates an empty object called websitesettings, and if isPending is false and there is no error, it uses Object.assign to merge the contents of all the objects in the data array into the websitesettings object.
  let websitesettings = {};
  if (!websitesettingsData.isPending && !websitesettingsData.error) {
    websitesettings = Object.assign({}, ...websitesettingsData.data);


  }
  return (
    <>
      {websitesettingsData.isPending  ? (
        <Header />
      ) : websitesettingsData.error || isPending ? (
        <ErrorMessage>{websitesettingsData?.error?.message}</ErrorMessage>
      ) : (
        <>
          <Helmet>
            <title>Agent Profile - BlackBrick</title>
            <meta name="description" content="Agent Profile" />
            <meta name="keywords" content={websitesettings.keywords} />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content="https://blackbrickproperty.com/agent"
            />
            <meta property="og:title" content="Agent Profile - BlackBrick" />
            <meta property="og:description" content="Meet the team" />
            <meta
              property="og:image"
              content={websitesettings.teampageheader}
            />
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content="https://blackbrickproperty.com/agent"
            />
            <meta property="twitter:title" content="Agent Profile - BlackBrick" />
            <meta property="twitter:description" content="Agent Profile" />
            <meta
              property="twitter:image"
              content={websitesettings.teampageheader}
            />
          </Helmet>
          <div
            className="agent-header-container"
          >
            <Header />
            <div 
                style={{
                  backgroundImage: `url(${data?.coverImage || "https://res.cloudinary.com/dohzddznt/image/upload/v1713943909/blackbrick/placeholder_fmw7fw.svg"})`,
                }}
                className="show_desktop agent-header-titles">
                  <div class="text-overlay">
                      <h2 className="agent-content">{data?.jobTitle}</h2>
                      <h1 className="agent-heading">{data?.name}</h1>
                      <div className="agent-contact-container">
                          <h2 className="agent-content">Email: <a className="agent-content" href={`mailto:${data?.email}`}>{data?.email}</a></h2>
                          {data?.ContactNumber && (
                              <h2 className="agent-content">
                                  Tel:{" "}
                                  <a
                                      className="agent-content"
                                      href={`tel:+${data?.ContactNumber}`}
                                  >
                                      +{data?.ContactNumber.substring(0, 3)}{" "}
                                      {data?.ContactNumber.substring(3, 6)}{" "}
                                      {data?.ContactNumber.substring(6)}
                                  </a>
                              </h2>
                          )}

                      </div>
                      <div className="button-container">
                        <button  className="agent-submit-btn agent-content" onClick={togglePopup} >Contact {data?.name?.split(" ")[0]}</button>
                      </div>
                      <div className="agent-social-icons">
                        <a key={1} href={`mailto:${data?.email}`} className="agent-social-icon">
                          <img src={`/assets/images/social-icons/message.png`} alt="email" />
                        </a>
                        <a key={2} href="#"  onClick={()=>whatsappContact(data?.ContactNumber)} className="agent-social-icon">
                          <img src={`/assets/images/social-icons/whatsapp.png`} alt="whatsapp" />
                        </a>
                        <a key={3} href={`tel:${data?.ContactNumber}`} className="agent-social-icon">
                            <img src="/assets/images/social-icons/phonecall.png" alt="phone call" />
                        </a>
                        {data?.facebookLink &&
                        <a key={4} href={data?.facebookLink} className="agent-social-icon">
                          <img src="/assets/images/social-icons/facebook.png" alt="facebook" />
                        </a>
                        }
                        {data?.instagramLink &&
                        <a key={5} href={data?.instagramLink} className="agent-social-icon">
                          <img src="/assets/images/social-icons/instagram.png" alt="instagram" />
                        </a>
                        }
                        {data?.linkedinLink &&
                        <a key={6} href={data?.linkedinLink} className="agent-social-icon">
                          <img src="/assets/images/social-icons/linkedin.png" alt="linkedin" />
                        </a>
                        }
                        {data?.xLink &&
                        <a key={7} href={data?.xLink} className="agent-social-icon">
                          <img src="/assets/images/social-icons/x.png" alt="x" />
                        </a>
                        }
                  </div>
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${data?.image || "https://res.cloudinary.com/dohzddznt/image/upload/v1713943909/blackbrick/placeholder_fmw7fw.svg"})`,
                  position: 'relative',
                }}
                className="show_mobile agent-header-titles-mobile"
              >
                <div className="text-overlay_mobile">
                  <h1 className="agent-heading-mobile">{data?.name}</h1>
                  <h2 className="agent-content-mobile">{data?.jobTitle}</h2>
                  <div className="button-container-mobile">
                    <button className="agent-submit-btn-mobile agent-content-mobile" onClick={togglePopup}>
                      Contact {data?.name?.split(" ")[0]}
                    </button>
                  </div>
                  
                </div>
              </div>
        </div>
      </>
    )}
      <>
      {isConnectPopupOpen && 
        <ConnectPopup 
          onClose={togglePopup} 
          showProfileButton = {false}
          AgentTitle={data?.jobTitle}
          AgentEmail = {data?.email}
          AgentCoverImage = {data?.coverImage}
          AgentContactNumber={data?.ContactNumber}
          AgentID ={data?.AgentID}
          Agent ={data?.name}
          description= {data?.description}
          facebookLink = {data?.facebookLink}
          instagramLink= {data?.instagramLink}
          linkedinLink= {data?.linkedinLink}
          xLink = {data?.xLink}
          agentProfileExists = {!!data}        
          />
      }
      </>
      <AgentReadMore 
          readMore= {true} 
          h1Content = {
          data?.tagLine1 || `${data?.name} understands that every client has unique needs and goals, and takes the time to listen, understand and offer the right advise.`
        } h2Content =  {
          data?.tagLine2 || `Known for their professionalism, integrity, and personalized approach, ${data?.name} strives to deliver outstanding results for both buyers and sellers. They excel at guiding clients through the entire real estate process, from property valuation and market analysis to negotiating offers and closing transactions.`
        } 
      />
      <FeaturedListingsForSale Agent ={data?.name} AgentID ={data?.AgentID}/>
      <FeaturedListingsForRent Agent ={data?.name} AgentID ={data?.AgentID} />
      <Testimonial color="#fff" />
      <Footer />
    </>
  );
};

export default AgentProfile;
