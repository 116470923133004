import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import { RingLoader } from "react-spinners";
import Header from "../components/header";
import Footer from "../components/footer";
import ErrorMessage from "../components/loading-error/error";
import { useQuery } from "@tanstack/react-query";
import { getFlyerImage } from "../context/endpoints";

const FlyerPreview = () => {
  const { refNo, propertyType, flyerType } = useParams();
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState("");
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [formData, setFormData] = useState({
    startDay: "",
    endDay: "",
  });

  const weekdayNames = ["SUN", "MON", "TUES", "WED", "THURS", "FRI", "SAT"];

  const { isFetching, isError, data, error: queryError } = useQuery({
    queryKey: ["GetFlyerImage", refNo, propertyType, flyerType],
    queryFn: () => getFlyerImage(refNo, propertyType, flyerType),
    onError: (err) => setError(err),
  });

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (!isFetching && data) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setLoading(false);
      };
      reader.readAsDataURL(data);
    } else if (isError) {
      setError("Something Went Wrong. Try again");
      setLoading(false);
    }
  }, [isFetching, data, isError]);

  useEffect(() => {
    // Show the popup only if flyerType is 'openHouse' or 'privateBroker'
    if (flyerType === 'openHouse' || flyerType === 'privateBroker') {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [flyerType]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Check if start day and end day are filled
    if (flyerType==="privateBroker" && formData.date && formData.startTime && formData.endTime) {
      // Call API with formData
      getFlyerImage(refNo, propertyType, flyerType, formData.date, formData.startTime, formData.endTime)
        .then((response) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageSrc(reader.result);
            setLoading(false);
          };
          reader.readAsDataURL(response);
          setShowPopup(false);
        })
        .catch((error) => {
          setError("Failed to fetch flyer image. Please try again.");
          setLoading(false);
        });
    } else if (flyerType==="openHouse" &&formData.startDay && formData.endDay) {
      // Call API with formData
      getFlyerImage(refNo, propertyType, flyerType,"", "","", formData.startDay, formData.endDay)
        .then((response) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageSrc(reader.result);
            setLoading(false);
          };
          reader.readAsDataURL(response);
          setShowPopup(false);
        })
        .catch((error) => {
          setError("Failed to fetch flyer image. Please try again.");
          setLoading(false);
        });
    } else {
      setError("Please fill in all fields.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Header />
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <RingLoader color={"#3d3935"} loading={loading} css={override} size={150} />
        </div>
      )}
      {!loading && !isError && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 100px)", // Adjust height to accommodate header and footer
          }}
        >
          <img src={imageSrc} alt="Flyer" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
        </div>
      )}
      {isError && <ErrorMessage>{error}</ErrorMessage>}
      <Footer />
      {showPopup && flyerType==="privateBroker"&& (
        <div className="popup" style={popupStyles}>
          <button className="close-button" style={closeButtonStyle} onClick={handleClosePopup}>X</button>
          <div className="popup-contents" style={popupContentStyles}>
            <h2>Enter Details</h2>
            <form onSubmit={handleFormSubmit}>
              <div>
                <div>
                  <label htmlFor="date" style={labelStyles}>Date:</label>
                </div>
                <input type="date" className="flyer-date-input" id="date" name="date" value={formData.date} onChange={handleInputChange} />
              </div>
              <div>
                <div>
                  <label htmlFor="startTime" style={labelStyles}>Start Time:</label>
                </div>
                <input type="time" className="flyer-date-input" id="startTime" name="startTime" value={formData.startTime} onChange={handleInputChange} />
              </div>
              <div>
                <div>
                  <label htmlFor="endTime" style={labelStyles}>End Time:</label>
                </div>
                <input type="time" className="flyer-date-input" id="endTime" name="endTime" value={formData.endTime} onChange={handleInputChange} />
              </div>
              <div>
                <button type="submit" style={buttonStyles}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showPopup && flyerType === "openHouse" && (
        <div className="popup" style={popupStyles}>
          <button className="close-button" style={closeButtonStyle} onClick={handleClosePopup}>X</button>
          <div className="popup-contents" style={popupContentStyles}>
            <h2>Enter Details</h2>
            <form onSubmit={handleFormSubmit}>
              <div>
                <div>
                <label htmlFor="startDay" style={labelStyles}>Start Day:</label>
                </div>
                <select id="startDay" name="startDay" value={formData.startDay} onChange={handleInputChange}>
                  <option value="">Select start day</option>
                  {weekdayNames.map((day, index) => (
                    <option key={index} value={day}>{day}</option>
                  ))}
                </select>
              </div>
              <div>
              <div>
                <label htmlFor="endDay" style={labelStyles}>End Day:</label>
               </div>
                <select id="endDay" name="endDay" value={formData.endDay} onChange={handleInputChange}>
                  <option value="">Select end day</option>
                  {weekdayNames.map((day, index) => (
                    <option key={index} value={day}>{day}</option>
                  ))}
                </select>
              </div>
              <div>
                <button type="submit" style={buttonStyles}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const popupStyles = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "5px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Add a slight shadow
  zIndex: "999",
};

const popupContentStyles = {
  maxWidth: "400px",
  margin: "0 auto",
  textAlign: "center", // Align content to the center
};

const labelStyles = {
  bottom: "40%",
  color: "black",
  textAlign:"center" // Set label font color to black
};

const buttonStyles = {
  backgroundColor: "#3d3935", // Set button background color
  color: "#fff", // Set button text color to white
  border: "none", // Remove button border
  borderRadius: "3px", // Add border radius to button
  padding: "10px 20px", // Add padding to button
  cursor: "pointer", // Set cursor to pointer on hover
  marginTop: "10px", // Add top margin to button
};
const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  fontSize: "20px",
  fontWeight: "bold",
  color: "#555",
};
export default FlyerPreview;
