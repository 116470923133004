import React from "react";
import Header from "../components/header";
import ContactForm from "../components/contact-form";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Advisory = () => {
  return (
    <>
      <Helmet>
        <title>Development Advisory - BlackBrick</title>
        <meta
          name="description"
          content="Strategic Advisory Services for Developers & Property Owners. Residential/Commercial Real Estate, Hospitality, Yachting & Tourism"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://blackbrickproperty.com/advisory"
        />
        <meta property="og:title" content="Development Advisory - BlackBrick" />
        <meta
          property="og:description"
          content="Strategic Advisory Services for Developers & Property Owners. Residential/Commercial Real Estate, Hospitality, Yachting & Tourism"
        />
        <meta
          property="og:image"
          content="/assets/images/advisory-image3.jpg"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://blackbrickproperty.com/advisory"
        />
        <meta
          property="twitter:title"
          content="Development Advisory - BlackBrick"
        />
        <meta
          property="twitter:description"
          content="Strategic Advisory Services for Developers & Property Owners. Residential/Commercial Real Estate, Hospitality, Yachting & Tourism"
        />
        <meta
          property="twitter:image"
          content="/assets/images/advisory-image3.jpg"
        />
      </Helmet>
      <Header />
      <div className="advisory-header-container">
        <div className="advisory-header-titles">
          <div className="container">
            <h1>Development advisory</h1>
            <h2>Mixed-Use Lifestyle Destinations</h2>
            <h3>Development & Operations Advisory Services</h3>
            <p>
              Strategic Advisory Services for Developers & Property Owners.
              <br />
              Residential/Commercial Real Estate, Hospitality, Yachting &
              Tourism
            </p>
          </div>
        </div>
      </div>
      <div className="advisory-container">
        <div className="container">
          <div className="advisory-texts-container">
            <h3>Owners Representation</h3>
            <div className="advisory-texts">
              <p>
                Knowledge, reliability and visionary insight is critical for
                Master Developers and Project Owners, we understand these needs
                and our Team of inhouse and partnered experts deliver
                transparent and independent supervision, advice and guidance.
              </p>
              <p>
                We work to ensure our clients best interests are at the center
                of every decision made throughout the master planning, design
                development, detailed design, construction, and operational
                start-up phases of a project.
              </p>
            </div>
            <img
              src="/assets/images/advisory-image1.jpg"
              alt="advisory images"
            />
          </div>
        </div>
        <div className="advisory-texts-container advisory-texts-second">
          <div className="container">
            <h3>A Holistic Approach</h3>
            <div className="advisory-texts">
              <p>
                Our viewpoint is to take a holistic assessment of each lifestyle
                destination from initial concepts through final execution. As an
                independent Owners Representative, we oversee, guide and support
                project teams to ensure the developments positioning,
                functionality and feasibility is within the client's
                expectations, if not exceeding such expectation.
              </p>
              <p>
                Our role is to work with the project teams to create an
                innovative and unique lifestyle destination, while in parallel
                resolve problems in real time and maintain contact with the
                owner so they are informed. This drives timely decisions and
                issues can be mitigated before they become a problem.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="advisory-texts-details-container">
            <div className="advisory-texts-details-left">
              <h3>Best Use Analysis</h3>
              <p>
                Review and assess the existing and predicted market conditions ,
                identified development opportunities, proposed quantum of real
                estate to be developed, asset class mix, detailed concept and
                module specifications.
                <br />
                &nbsp;
                <br />
                Additionally, we review and assess the financial
                feasibility/viability of the project, recommending a strategic
                phasing strategy for the project that would meet the client’s
                objectives for the development while addressing identified
                market demands.
              </p>
              <h3>Masterplan Oversight</h3>
              <p>
                Our experienced team provides masterplan oversight and review
                services to assist lead designers create dynamic and sustainable
                mixed-use developments to fulfill the needs of a large
                anticipated population influxes in green field sites or urban
                rejuvenation projects.
                <br />
                &nbsp;
                <br />
                Our Team’s knowledge of creating waterfront lifestyle
                destinations is signifi-cant with experience in the local MENA
                region and internationally.
              </p>
            </div>
            <div className="advisory-texts-details-right">
              <img
                src="/assets/images/advisory-image2.png"
                alt="design to live images"
              />
            </div>
          </div>
        </div>
        <div className="advisory-texts-container advisory-texts-third">
          <div className="container">
            <h3>Marina Lifestyle – Design, Development & Operations</h3>
            <div className="advisory-texts">
              <p>
                Our BlackBrick team has extensive experience in providing
                innovative development and management services for marina
                lifestyle destinations from marina planning and design to
                engineering, development, construction management, operations
                and marketing, we have the proven capabilities.
              </p>
              <p>
                We Provide a multidisciplinary team of experts that includes
                designers, development professionals, marketers and marina
                operations specialists, who interact as a co-ordinated team and
                uniquely integrate their world-class capabilities to deliver
                unequalled luxury yachting destinations.
              </p>
            </div>
            <img
              src="/assets/images/advisory-image3.jpg"
              alt="advisory images"
            />
          </div>
        </div>
        <div className="container">
          <div className="other-services-container">
            <h3>Other Services</h3>
            <div className="other-services-boxes">
              <div className="other-services-box">
                <h4>Legal Advisory</h4>
                <p>
                  BlackBrick has partnered with key industry professionals to
                  provide a varying scope of legal and commercial services. The
                  Team can drive or oversee the legal aspects of a development
                  with the final scope being determined by the Owners.
                  <br />
                  &nbsp;
                  <br />
                </p>
                <img
                  src="/assets/images/other-services-box-1.png"
                  alt="advisory images"
                />
              </div>
              <div className="other-services-box">
                <h4>Sales & Marketing</h4>
                <p>
                  Our strength is to ensure our clients achieve the project
                  returns that were targeted. The BlackBrick team drives the
                  sales and marketing process from the outset and works in the
                  project team in all phases of the development. Our service
                  ranges from the conception to the successful marketing and
                  after-sales activities.
                </p>
                <img
                  style={{ objectPosition: "0 -200px" }}
                  src="/assets/images/other-services-box-2.png"
                  alt="advisory images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm
        formTitle={"our bespoke services?"}
        formSubtitle={"Interested in"}
      />
      <Footer />
    </>
  );
};

export default Advisory;
