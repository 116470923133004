import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getSettingsFn, getBlogFn } from "../context/endpoints";
import { useParams, Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import DescriptionReadMore from "../components/description-read-more";

const BlogDetails = () => {
  const { id } = useParams();

  // Fetch settings data
  const { data: settingsData, error: settingsError, isLoading: isSettingsLoading } = useQuery({
    queryKey: ["settingsData"],
    queryFn: getSettingsFn,
  });

  // Fetch blog data
  const { data: blogData, error: blogError, isLoading: isBlogLoading } = useQuery({
    queryKey: ["BlogData", id],
    queryFn: () => getBlogFn(id),
    enabled: !!id, // Ensure query only runs if id is available
  });

  // Determine whether data is still loading or if there's an error
  const isLoading = isSettingsLoading || isBlogLoading;
  const hasError = settingsError || blogError;

  useEffect(() => {
    if (blogData?.id) {
      // Find the script tag
      const scriptTag = document.querySelector('script[src="https://seoscribe.app/templates/article.js"]');
      
      if (scriptTag) {
        // Replace BLOGID with the actual id
        const updatedUniqueID = scriptTag.getAttribute('data-uniqueID').replace('BLOGID', blogData.id);
        scriptTag.setAttribute('data-uniqueID', updatedUniqueID);
      }
    }
  }, [blogData?.id]);

  return (
    <>
      <Header />
      <div className="backtoallresults">
        <Link to={`/blogs`}>
          <h2>
            <span>&#60;</span> back to all results
          </h2>
        </Link>
      </div>
      {isLoading ? (
        <div className="container-secondary">
          <div className="journal-details-container">
            <div className="details-container">
              <div className="details-left-side">
                {[...Array(8)].map((_, index) => (
                  <Skeleton key={index} variant="text" width="100%" sx={{ mb: 2 }} />
                ))}
              </div>
              <div className="details-right-side">
                <Skeleton variant="rectangular" width="100%" sx={{ mb: 2, height: 597 }} />
                <div className="details-description-container">
                  {[...Array(3)].map((_, index) => (
                    <Skeleton key={index} variant="text" width="100%" sx={{ mb: 2 }} />
                  ))}
                </div>
                <div className="details-right-side-images">
                  {[...Array(2)].map((_, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Skeleton variant="rectangular" width="100%" sx={{ height: 313 }} />
                    </Box>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : hasError ? (
        <ErrorMessage>{hasError.message}</ErrorMessage>
      ) : (
        <>
          <Helmet>
            <title>{`${blogData.title || ""} - BlackBrick`}</title>
            <meta name="description" content={blogData.content.slice(0, 160) || ""} />
            <meta name="keywords" content={blogData.keywords || ""} />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://blackbrickproperty.com/blogs/${blogData._id}`}
            />
            <meta
              property="og:title"
              content={`${blogData.title || ""} - BlackBrick`}
            />
            <meta
              property="og:description"
              content={blogData.content.slice(0, 160) || ""}
            />
            <meta property="og:image" content={blogData.bannerImageUrl || ""} />
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content={`https://blackbrickproperty.com/blogs/${blogData._id}`}
            />
            <meta
              property="twitter:title"
              content={`${blogData.title || ""} - BlackBrick`}
            />
            <meta
              property="twitter:description"
              content={blogData.content.slice(0, 160) || ""}
            />
            <meta property="twitter:image" content={blogData.bannerImageUrl || ""} />
          </Helmet>
          <div className="container-secondary">
            <div className="journal-details-container">
              <div className="details-container">
                
                <div style ={{width:"100%"}}className="details-right-side">
                <h1 style={{marginTop:"20px"}}>{blogData.title}</h1>
                  <p style={{margin:"20px 0px 20px 0px"}}>{blogData.keywords}</p>
                <div className="banner-image">
                      <img src={blogData.bannerImageUrl} alt="Banner" style={{ width: '100%' }} />
                    </div>
                    <div className="">
                    <div className="details-description">
                      <DescriptionReadMore>{ blogData.content }</DescriptionReadMore>
                    </div>
                  </div>
                  <div className="tags-container">
                    {blogData.keywords.split(",").map((tag, index) => (
                      <Link key={index} to={`/blogs?topic=${tag.trim()}`}>
                        <h4>{tag.trim()}</h4>
                      </Link>
                    ))}
                  </div>
                  <div className="details-right-side-images">
                    {blogData.image && blogData.image.slice(1, 4).map((item, index) => (
                      <img key={index} src={item} alt={`Additional image ${index}`} style={{ width: '100%', height: 'auto' }} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default BlogDetails;
