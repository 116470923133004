import React, { useEffect, useState } from 'react';
import { getGoogleReviews } from "./../context/endpoints";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import Skeleton from "@mui/material/Skeleton";
import ErrorMessage from "./loading-error/error";

const GoogleReviews = ({ placeId, apiKey, color = "#fff" }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await getGoogleReviews(placeId, apiKey);
        setReviews(response.result?.reviews || []);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchReviews();
  }, [placeId, apiKey]);

  const formatRating = (rating) => {
    return '★'.repeat(rating) + '☆'.repeat(5 - rating);
  };

  return (
    <div style={{ backgroundColor: color }} className="google-reviews-container testimonial">
      <div className="container">
        <h4 style={{ textAlign: 'center', paddingBottom:"0px" }}>Google Reviews</h4>
        {loading ? (
          <Skeleton variant="text" width="100%">
            <div style={{ paddingTop: "23px" }} />
          </Skeleton>
        ) : error ? (
          <ErrorMessage>{error.message}</ErrorMessage>
        ) : (
          <Swiper
            autoHeight={true}
            navigation={true}
            grabCursor={true}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            {reviews.length > 0 &&
              reviews.map((review, index) => (
                <SwiperSlide key={index} className="google-review-item">
                  <div className="google-review-content">
                    <div className="google-review-rating">{formatRating(review.rating)}</div>
                    <div className="google-review-author">{review.author_name}</div>
                    <div className="google-review-date">{new Date(review.time * 1000).toLocaleDateString()}</div>
                    <div className="google-review-text" style={{ borderBottom: 'none', marginTop: '10px' }}>{review.text}</div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default GoogleReviews;
