import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import ContactForm from "../contact-form";
import PropertyCard from "../property-card";
import BuildSection from "../build-section";
import { convertToCurrency } from "../../hooks/currency";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import ErrorMessage from "../loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import DescriptionReadMore from "../description-read-more";
import { postClickEntity } from "../../context/endpoints";
import ConnectPopup from "./../connect-popup";
import VideoPopup from "./../video-popup";

import { getAgentProfile } from "./../../context/endpoints";

export default function PropertyDetails({ property, similarProperties }) {
  console.log(property.data);
  const [agentProfile, setAgentProfile] = useState(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [openVideo, SetVideo] = useState(false);

  const location = useLocation();
  const type = location.pathname.split("/")[2];
  const myRef = useRef(null);
  const shortContentRef = useRef(null);
  const fullContentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  
  useEffect(() => {
    return () => {
      // Unmount logic here
      // For example, you can clear the state
      setAgentProfile(null);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (property && property.data && property.data.AgentID) {
        try {
          const response = await getAgentProfile(property.data.AgentID);
          setAgentProfile(response); // Store response in state
          setIsLoading(false)
        } catch (error) {
          setIsLoading(false)
          console.error("Error fetching agent profile:", error);
        }
      }
    };

    fetchData();
  }, [property]);

  useEffect(() => {
    if (!property?.data?.Remarks) {
      return; // Return early if Remarks is falsy or undefined
    }

    const extractedContent = property.data.Remarks.replace(
      /<!\[CDATA\[(.*?)\]\]>/s,
      "$1"
    );

    let shortContent;

    const firstPeriodIndex = extractedContent.indexOf(".");

    if (firstPeriodIndex !== -1 && firstPeriodIndex <= 300) {
      shortContent = extractedContent.substr(0, firstPeriodIndex + 1).concat(" ...");
    } else {
      shortContent = extractedContent.substr(0, 300).concat(" ...");
    }

    // Replace newlines with <br> tags
    const shortContentWithLineBreaks = shortContent.replace(/\n/g, "<br>");

    // Apply basic styling
    const shortStyledContent = `<p style="font-size: 16px; color: #333;">${shortContentWithLineBreaks}</p>`;

    // Check if the ref is defined before updating innerHTML
    if (shortContentRef.current) {
      shortContentRef.current.innerHTML = shortStyledContent;
    }

    // Full content
    const fullContentWithLineBreaks = extractedContent.replace(/\n/g, "<br>");
    const fullStyledContent = `<p style="font-size: 16px; color: #333;">${fullContentWithLineBreaks}</p>`;

    // Check if the ref is defined before updating innerHTML
    if (fullContentRef.current) {
      fullContentRef.current.innerHTML = fullStyledContent;
    }
  }, [property?.data?.Remarks]);

  useEffect(() => {
    const fetchData = async () => {
      if (property && property.data && property.data.AgentID) {
        try {
          const response = await getAgentProfile(property.data.AgentID);
          setAgentProfile(response); // Store response in state

        } catch (error) {
          console.error("Error fetching agent profile:", error);
        }
      }
    };
    fetchData();
  }, [property]);

  const togglePopup = () => {
    if (window.gtag) {
      window.gtag('event', 'agent_connect', {
        'send_to': 'G-F4J8CJGY6Y'
      });
    }

    setIsPopupOpen(!isPopupOpen);
  };

  const openVideoPopup = () => {
    SetVideo(!openVideo);
  };
  const callAdvisor = (ContactNumber) =>{

    if (window.gtag) {
      window.gtag('event', 'agent_call', {
        'send_to': 'G-F4J8CJGY6Y'
      });
    };
    window.location.href = `tel:${ContactNumber}` 
  };
  // Scroll to contact form
  const ScrollToContactForm = () => myRef.current.scrollIntoView();
  const whatsappContact = async (ContactNumber, refNo, propertyType) => {

    if (window.gtag) {
      window.gtag('event', 'agent_whatsapp', {
        'send_to': 'G-F4J8CJGY6Y'
      });
    }

    const message = `Hello, I'm interested in the property. Here's the link for your reference: www.blackbrickproperty.com/properties/${type}/property/${refNo}`; // Updated message
    const whatsappUrl = `https://wa.me/${ContactNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
    await postClickEntity(refNo, propertyType, "whatsapp us");
  };
  const handleShare = () => {
    if (window.gtag) {
      window.gtag('event', 'agent_share', {
        'send_to': 'G-F4J8CJGY6Y'
      });
    }
    if (navigator.share) {
        navigator.share({
            title: property.data.MarketingTitle,
            text: property.data.Remarks.substr(0, 300),
            url: window.location.href,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
        // Fallback for browsers that do not support the Web Share API
        // Here you can provide a fallback behavior, such as opening a custom share dialog
        alert('Unfortunately, sharing is not supported in this browser. You can manually share the link.');
    }
};

    
  return (
    <>
       <Helmet>
        <title>{`${property.data?.MarketingTitle ?? 'Default Title'} - BlackBrick`}</title>
        <meta name="description" content={property.data?.Remarks ?? 'Default description'} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://blackbrickproperty.com/properties/${type}/property/${property.data?.RefNo ?? 'default-refno'}`} />
        <meta property="og:title" content={`${property.data?.MarketingTitle ?? 'Default Title'} - BlackBrick`} />
        <meta property="og:description" content={property.data?.Remarks?.substr(0, 300) ?? 'Default description'} />
        <meta property="og:image" content={property.data?.Images?.Image[0]?.ImageURL ?? 'https://res.cloudinary.com/dohzddznt/image/upload/v1716303409/IMG_0411_1_ehnukg.jpg'} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://blackbrickproperty.com/properties/${type}/property/${property.data?.RefNo ?? 'default-refno'}`} />
        <meta property="twitter:title" content={`${property.data?.MarketingTitle ?? 'Default Title'} - BlackBrick`} />
        <meta property="twitter:description" content={property.data?.Remarks?.substr(0, 300) ?? 'Default description'} />
        <meta property="twitter:image" content={property.data?.Images?.Image[0]?.ImageURL ?? 'https://res.cloudinary.com/dohzddznt/image/upload/v1716303409/IMG_0411_1_ehnukg.jpg'} />
      </Helmet>
      <Header />
      {isLoading ? null : isLoading ? (
        <></>
      ) : (
      <>
      <div className="backtoallresults">
        <Link to={`/properties/${type}`}>
          <h2>
            <span>&#60;</span>back to all results
          </h2>
        </Link>
      </div>
      {property.isPending ? (
        <>
          <div className="container-secondary">
            <div className="property-details-container">
              <div className="details-container">
                <div className="details-left-side">
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "65px", marginBottom: "5px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px", marginBottom: "30px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px", marginBottom: "25px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                  <Skeleton variant="text" width="100%">
                    <div style={{ paddingTop: "23px" }} />
                  </Skeleton>
                </div>
                <div className="details-right-side">
                  <div className="details-right-side-main-image">
                    <Skeleton variant="rectangular" width="100%">
                      <div style={{ paddingTop: "597px" }} />
                    </Skeleton>
                  </div>
                  <div
                    className="details-description-container"
                    style={{ display: "block" }}
                  >
                    <Skeleton variant="text" width="100%">
                      <div style={{ paddingTop: "23px" }} />
                    </Skeleton>
                    <Skeleton variant="text" width="100%">
                      <div style={{ paddingTop: "23px" }} />
                    </Skeleton>
                    <Skeleton variant="text" width="100%">
                      <div style={{ paddingTop: "23px" }} />
                    </Skeleton>
                  </div>
                  <div className="details-right-side-images">
                    {Array.from({ length: 2 }).map((_, index) => (
                      <Box key={index}>
                        <Skeleton variant="rectangular" width="100%">
                          <div style={{ paddingTop: "313px" }} />
                        </Skeleton>
                      </Box>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContactForm formTitle={""} formSubtitle={""} />
          <div className="similar-properties-container">
            <div className="container">
              <div className="similar-properties-grid">
                {Array.from({ length: 3 }).map((_, index) => (
                  <Box key={index}>
                    <Skeleton variant="rectangular" width="100%">
                      <div style={{ paddingTop: "259px" }} />
                    </Skeleton>
                    <Skeleton variant="text" width="100%">
                      <div
                        style={{ paddingTop: "65px", marginBottom: "-9px" }}
                      />
                    </Skeleton>
                    <Skeleton variant="text" width="100%">
                      <div style={{ paddingTop: "23px" }} />
                    </Skeleton>
                  </Box>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : property.error ? (
        <ErrorMessage>{property.error.message}</ErrorMessage>
      ) : (
        <>
          <Helmet>
            <title>{`${
              property.data.MarketingTitle?.replace(/\|/g, " | ") || ""
            } - BlackBrick`}</title>
            <meta
              name="description"
              content={property.data.Remarks.substr(0, 300)}
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://blackbrickproperty.com/properties/${type}/property/${property.data.RefNo}`}
            />
            <meta
              property="og:title"
              content={`${property.data.MarketingTitle?.replace(/\|/g, " | ")} - BlackBrick`}
            />
            <meta
              property="og:description"
              content={property.data.Remarks.substr(0, 300)}
            />
            <meta property="og:image" content="" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:url"
              content={`https://blackbrickproperty.com/properties/${type}/property/${property.data.RefNo}`}
            />
            <meta
              property="twitter:title"
              content={`${property.data.MarketingTitle} - BlackBrick`}
            />
            <meta
              property="twitter:description"
              content={property.data.Remarks.substr(0, 300)}
            />
            <meta property="twitter:image" content="" />
          </Helmet>
          <div className="container-secondary">
            <div className="property-details-container">
              <div className="details-container">
                <div className="details-left-side">
                  <h1>{property.data.MarketingTitle?.replace(/\|/g, " | ")}</h1>
                  {property.data.PriceOnApplication === "No" ?
                   <>
                  <h3>
                    AED{" "}
                    {!!property.data.SellPrice
                      ? convertToCurrency(property.data.SellPrice)
                      : convertToCurrency(property.data.Rent)}
                  </h3>
                  </>: <h3>Price on Application</h3>}
                  <h4>{property.data.Bedrooms > 0 ? property.data.Bedrooms ===1 ?`${property.data.Bedrooms} Bedroom` : `${property.data.Bedrooms} Bedrooms` : 'Studio'}</h4>
                  <h4>{property.data.NoOfBathrooms == 1 ? `${property.data.NoOfBathrooms} Bathroom`: `${property.data.NoOfBathrooms} Bathrooms`} </h4>
                  <h4>
                    {convertToCurrency(parseFloat(property.data.BuiltupArea))}{" "}
                    BUA
                  </h4>
                  <h4>{convertToCurrency(parseFloat(property.data.PlotArea))} Plot Area</h4>
                  <h5>
                    {property.data.Community}, {property.data.CityName}
                  </h5>
                <div className="show_desktop">
                  <img src ={agentProfile?.image || property.data?.AgentPhoto} alt="Agent Picture"/>
                  <div className="detail-adivor-bio">
                    <h2 className="detail-left-side-name">{agentProfile?.name ||property.data.Agent}</h2>
                    <h2 className="detail-left-side-title">{agentProfile?.jobTitle || "PROPERTY ADVISOR"}</h2>
                  </div>
                  <div className="button-left-side-title" >
                  <button onClick={togglePopup}>
                      Connect
                  </button>
                  {isPopupOpen && 
                    <ConnectPopup 
                      onClose={togglePopup} 
                      AgentTitle={agentProfile?.jobTitle || "PROPERTY ADVISOR"}
                      AgentEmail = {agentProfile?.email || property.data?.SalesmanEmail }
                      AgentCoverImage = {agentProfile?.coverImage}
                      AgentContactNumber={agentProfile?.ContactNumber || property.data?.ContactNumber}
                      AgentID ={agentProfile?.AgentID || property.data?.AgentID}
                      Agent ={agentProfile?.name || property.data?.Agent}
                      description= {agentProfile?.description}
                      facebookLink = {agentProfile?.facebookLink}
                      instagramLink= {agentProfile?.instagramLink}
                      linkedinLink= {agentProfile?.linkedinLink}
                      xLink = {agentProfile?.xLink}
                      agentProfileExists = {!!agentProfile}
                    />
                  }

                  </div>
                  {agentProfile &&
                    <div 
                      className="button-left-side-title"
                    >
                    <Link 
                      to={`/agent/${property.data.AgentID}`}
                    >
                    <button>
                        View Profile
                    </button>
                    </Link>
                    </div>
                  }
                  
                </div>
                  {/* <div className="details-left-side-button">
                    <a href={`http://maps.google.com/maps?q=${property.data.ProGooglecoordinates}`} rel="noopener noreferrer" target="_blank">
                      <button>view on map</button>
                    </a>
                  </div> */}
                </div>
                <div className="details-right-side">
                  <div className="details-right-side-main-image">
                    {property.data.Images?.Image.length > 0 &&
                      property.data.Images?.Image.slice(0, 1).map(
                        (item, index) => (
                          <img
                            key={index}
                            src={item.ImageURL}
                            alt="property images"
                          />
                        )
                      )}

                    {property.data.Images?.Image.length > 0 &&
                      property.data.Images?.Image.filter(x => x.ImageURL.includes("youtu.be")).slice(0,1).map(
                        (item, index) => (
                          <Link key={index} onClick={openVideoPopup}>
                            <button className="property-watch-video">
                              <span style={{ fontSize: "1rem" }} className="play-icon">&#9658;</span> Watch Video
                            </button>
                            {openVideo && (
                              <VideoPopup onClose={openVideoPopup} videoLink={item.ImageURL}></VideoPopup>
                            )}
                          </Link>
                        )
                      )
                    }
                    <Link
                      to={`/properties/${type}/property/${property.data.RefNo}/gallery`}
                    >
                      <button className="property-more-images"><span style={{fontSize:"1rem"}}>&rsaquo;</span> more images</button>
                    </Link>

                   
                                        
                  </div>
                 
                  
              
                  <div className="details-description-container">
                    <div className="details-description">
                      <DescriptionReadMore shortContentRef ={shortContentRef} fullContentRef={fullContentRef}    children={property.data.Remarks}>
                      
                      </DescriptionReadMore>
                    </div>
                    <div className="details-description-buttons">
                      {/* <button onClick={ScrollToContactForm}>
                        request details
                      </button>
                      <button onClick={ScrollToContactForm}>
                        schedule a viewing
                      </button> */}
                      <button className="details-description-btn" onClick={() => callAdvisor(agentProfile?.ContactNumber || property.data?.ContactNumber)}>
                        Call us
                      </button>
                      <button  className="details-description-btn" onClick={()=>whatsappContact(agentProfile?.ContactNumber || property.data?.ContactNumber, property.data.RefNo, type)}>
                        WhatsApp
                      </button>
                      <button  className="details-description-btn" onClick={() =>handleShare()}>
                        Share
                      </button>
                    </div>
                  </div>
                  <div className="details-right-side-images">
                    {property.data.Images?.Image.length > 1 &&
                      property.data.Images?.Image.slice(1, 3).map(
                        (item, index) => (
                          <Link
                            key={index}
                            to={`/properties/${type}/property/${property.data.RefNo}/gallery`}
                          >
                            <img src={item.ImageURL} alt="property images" />
                          </Link>
                        )
                      )}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="container-secondary show_mobile">
            <div className="property-details-container">
              <div className="details-container">
                <div className="details-left-side">
                
                  <div className="">
                    <img src ={agentProfile?.image || property.data.AgentPhoto} alt="Agent Picture"/>
                      <div className="detail-adivor-bio">
                        <h2 className="detail-left-side-name">{agentProfile?.name || property.data.Agent}</h2>
                        <h2 className="detail-left-side-title">{agentProfile?.jobTitle || "PROPERTY ADVISOR"}</h2>
                      </div>
                    
                      <div className="button-left-side-title" >
                      <button onClick={togglePopup}>
                          Connect
                      </button>
                      {isPopupOpen && 
                        <ConnectPopup 
                          onClose={togglePopup} 
                          AgentTitle={agentProfile?.jobTitle || "PROPERTY ADVISOR"}
                          AgentEmail = {agentProfile?.email || property.data?.SalesmanEmail }
                          AgentCoverImage = {agentProfile?.coverImage}
                          AgentContactNumber={agentProfile?.ContactNumber || property.data?.ContactNumber}
                          AgentID ={agentProfile?.AgentID || property.data?.AgentID}
                          Agent ={agentProfile?.name || property.data?.Agent}
                          description= {agentProfile?.description}
                          facebookLink = {agentProfile?.facebookLink}
                          instagramLink= {agentProfile?.instagramLink}
                          linkedinLink= {agentProfile?.linkedinLink}
                          xLink = {agentProfile?.xLink}
                          agentProfileExists = {!!agentProfile}
                        />
                      }
                      {
                      agentProfile && 
                        <Link 
                          to={`/agent/${property.data.AgentID}`}
                        >
                        <button>
                            View Profile
                        </button>
                        </Link>
                      }
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          {/* <div ref={myRef}>
            <ContactForm
              formTitle={`${property.data.MarketingTitle}?`}
              formSubtitle={"Interested in"}
            />
          </div> */}
          {similarProperties.isPending ? null : similarProperties.error ? (
            <ErrorMessage>{similarProperties.error.message}</ErrorMessage>
          ) : (
            <>
              {similarProperties.data.properties.filter((item) => item.RefNo !== property.data.RefNo).length > 0 && (
                <div className="similar-properties-container">
                  <h2>Similar properties</h2>
                  <div className="container">
                    <div className="similar-properties-grid">
                      {similarProperties.data.properties
                        .filter((item) => item.RefNo !== property.data.RefNo)
                        .slice(0, 3)
                        .map((item, index) => (
                          <PropertyCard
                            key={index}
                            cardLink={`/properties/${type}/property/${item.RefNo}`}
                            cardImage={item.Images?.Image}
                            cardTitle={item.MarketingTitle}
                            cardLocation={item.Community}
                            cardBedrooms={item.Bedrooms}
                            cardPrice={
    
                              !!item.SellPrice
                                ? item.SellPrice
                                 : item.Rent
                            }
                            priceOnApplication ={property.PriceOnApplication}
                            RefNo={property.RefNo}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
      {/* <div className="build-section-secondary-color">
        <BuildSection />
      </div> */}
      </>
      )}
     
      <Footer />
    </>
  );
}
