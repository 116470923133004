import React, { useState, useEffect } from "react";

export default function DescriptionReadMore({ children }) {
  const [showFullDescription, setFullDescription] = useState(false);
  const [parsedContent, setParsedContent] = useState("");

  useEffect(() => {
    // Extract CDATA content using regex
    const cdataRegex = /<!\[CDATA\[(.*?)\]\]>/s;
    const match = children.match(cdataRegex);

    if (match && match[1]) {
      setParsedContent(match[1]);
    } else {
      // If CDATA not found, use the entire content
      setParsedContent(children);
    }
  }, [children]);

  // Find the first period within the first 300 characters
  const firstPeriodIndex = parsedContent;
  const displayedContent =  firstPeriodIndex <= 500
    ? parsedContent.substr(0, firstPeriodIndex + 1)
    : parsedContent.substr(0, 500);

  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  const containerStyle = {
    "fontFamily":"graphie, sans-serif",
    whiteSpace: "pre-line",
    fontStyle:"normal",
    textAlign: "justify",            // Align text to both left and right
    textJustify: "distribute-all-lines", // Distribute lines to both left and right
    margin: "0 auto",                // Center the container horizontally
    paddingRight: "90px",            // Add padding to the right
  };

  return (
    <>
      <div style={containerStyle}>
        <span dangerouslySetInnerHTML={{ __html: showFullDescription ? parsedContent : displayedContent }} />
      </div>
      {parsedContent.length > 300 ? (
        <button onClick={showFullDescriptionHandler}>
          Read {showFullDescription ? "less" : "more"}
        </button>
      ) : null}
    </>
  );
}
