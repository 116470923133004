import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import "./landingPage.css"
const LandingPage = () => {
    return (
        <>
            <Helmet>
                <title>BlackBrick Property</title>
            </Helmet>
            <div className="header-landing-page" >
            {/* <img
                src="https://blackbrickwebsite.s3.me-central-1.amazonaws.com/JGE_D38-64_202404162254211444.jpg"
            /> */}
          </div>
        </>
    );
};

export default LandingPage;
