import React, { useState, useRef, useEffect } from 'react';
import { userEnquiry } from "./../context/endpoints";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import 'react-toastify/dist/ReactToastify.css';

const RegisterForm = ({ onClose, onSubmitStatus }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState(''); // New state for the text box
  const [isSubmitted, setIsSubmitted] = useState(false); // New state for submission status
  const popupRef = useRef();

  const handleCloseOutside = (event) => {
    if (!popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  
  useEffect(() => {
    document.addEventListener('mousedown', handleCloseOutside);
    return () => {
      document.removeEventListener('mousedown', handleCloseOutside);
    };
  }, []);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      const currentURL = window?.location?.href;

      const name = `${firstName} ${lastName}`;
      const data = await userEnquiry(name, email, phoneNumber, additionalInfo, currentURL); // Send additionalInfo to the API
  
      if (data.statusCode === 200) {
        onSubmitStatus(true, data.result);
  
        // Meta Pixel tracking event
        if (window.fbq) {
          window.fbq('track', 'Lead');
        }
  
        // Google Analytics tracking event
        if (window.gtag) {
          window.gtag('event', 'subscription', {
            'send_to': 'G-F4J8CJGY6Y'
          });
        }
  
        setIsSubmitted(true); // Set submission status to true
      } else {
        onSubmitStatus(false);
      }
    } catch (error) {
      onSubmitStatus(false);
      // toast.error('An error occurred. Please try again.', {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    }
  };

  const handleFormClick = (event) => {
    event.stopPropagation(); // Prevent click event from bubbling up to the popup container
  };

  return (
    <>
      <div className="popup-container register-form">
        <div ref={popupRef} className="popup popup-connect">
          <div className="popup-inner">
            <button className="close-btn" onClick={e => onClose()}>X</button> {/* Close button with "X" icon */}
            <div className="popup-content popup-inner" onClick={handleFormClick}>
              {isSubmitted ? (
                <div className="success-message">
                  <h3>Thank you!</h3>
                  <p>Your details have been successfully received!
                  <br/>One of our dedicated Team members will be in touch within the next 24 hours to discuss your inquiry.
                  <br/>We look forward to assisting you and making your experience exceptional!   
                  </p>             
                  <button className="submit-btn popup-submit-button" onClick={onClose}>
                    Close
                  </button>
                </div>
              ) : (
                <>
                  <h3>How can we help you?</h3>
                  <p className="contact-form-instruction">
                    Whether you’re looking to buy, rent, or sell your home, we’re here to help.
                   </p>
                    <p>
                    Simply complete the form below, and one of our dedicated Team members will reach out to you shortly to assist with your inquiry.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="contact-form-top-list request-access-form">
                      <div className="contact-form-list-item">
                        <label htmlFor="firstName" className="form-label">FIRST NAME*:</label>
                        <input
                          type="text"
                          id="firstName"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          className="form-input"
                          required
                        />
                      </div>
                      <div className="contact-form-list-item">
                        <label htmlFor="lastName" className="form-label">LAST NAME*:</label>
                        <input
                          type="text"
                          id="lastName"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          className="form-input"
                          required
                        />
                      </div>
                      <div className="contact-form-list-item">
                        <label htmlFor="email" className="form-label">EMAIL*:</label>
                        <input
                          type="email"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-input"
                          required
                        />
                      </div>
                      <div className="contact-form-list-item">
                        <label htmlFor="phoneNumber" className="form-label">PHONE*:</label>
                        <div className="phone-input-wrapper">
                          <div className="phone-input-inner">
                            <PhoneInput
                              defaultCountry="AE"
                              country="AE"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e)}
                              className="form-input" // Ensure consistent sizing
                            />
                          </div>
                        </div>
                      </div>
                      <div className="contact-form-list-item">
                        <label htmlFor="additionalInfo" className="form-label">COMMENTS:</label>
                        <textarea
                          id="additionalInfo"
                          className="form-input text-area-register"
                          value={additionalInfo}
                          onChange={(e) => setAdditionalInfo(e.target.value)}
                          rows="4"
                          placeholder="Add any additional comments or information (optional)"

                        />
                      </div>
                    </div>
                    <button type="submit" className="submit-btn popup-submit-button">
                      Submit
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
