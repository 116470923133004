import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";

const TermsAndConditions = () => {
    return (
        <>
            <Helmet>
                <title>Membership Terms and Privacy Policy</title>
            </Helmet>
            <Header />
            <div className="terms-and-conditions" style={{margin: "0 20%"}}>
                <h1>Membership Terms and Privacy Policy, v1.0 - April 2023</h1>
                <section>
                    <h2>1. Membership of The Bond</h2>
                    <p>
                        A. The Bond is an exclusive networking and referral membership scheme offered by BlackBrick Property
                        LLC located at Unit 1114, Onyx Tower 2, The Greens, Dubai with trade licence number 947900
                        (“BlackBrick”). Membership is offered at the sole discretion of BlackBrick, and, irrespective to any
                        representations or terms in these Membership Terms and Conditions to the contrary, it can be revoked
                        at any time.
                    </p>
                    <p>
                        B. A limited number of memberships will be offered to chosen applicants, subject at all times to these
                        Membership Terms and Conditions, as updated from time to time.
                    </p>
                    <p>
                        C. Subject to Term 1.A. (above), application for membership to The Bond can be made by the following
                        means:
                    </p>
                    <ul style={{marginLeft:"20px"}}>
                        <li>a. Invitation by a BlackBrick authorised representative; and</li>
                        <li>b. Application through the BlackBrick website page for The Bond (for the time being at
                            <a href="https://www.blackbrickproperty.com/">https://www.blackbrickproperty.com/</a>, but as updated from time to time); and</li>
                        <li>c. Application though the BlackBrick App for The Bond available on Apple Store and Google Play.</li>
                    </ul>
                    <p>
                        By applying for membership of The Bond, you accept these Membership Terms and Conditions.
                    </p>
                    <p>
                        D. In order to become a member, also referred to as a Bondy in marketing and communication collateral,
                        you will need to submit the following details to BlackBrick:
                    </p>
                    <ul style={{marginLeft:"20px"}}>
                        <li>a. A copy of your emirates ID or if you are non-resident in the UAE a copy of your passport;</li>
                        <li>b. Your true, full name, date of birth, address, email address, and phone number; and</li>
                        <li>c. Details of your educational background, qualifications, and employment</li>
                    </ul>
                    <p>
                        E. Successful applicants shall be provided with a one-time login which, when used on The Bond App
                        (available at the Apple Store and Google Play), will grant the applicant membership. By using this one
                        time login you confirm your acceptance of these Membership Terms and Conditions.
                    </p>
                    <p>
                        F. Membership of The Bond and compliance with these Membership Terms and Conditions shall be
                        reviewed by BlackBrick, normally on an annual basis.
                    </p>
                    <p>
                        G. Membership is non-transferrable and is personal to the member. You cannot add additional members
                        to your membership or share your membership credentials, logins, access details, or access device with
                        any other individual.
                    </p>
                </section>
                <section>
                    <h2>2. Excluded Persons</h2>
                    <p>
                        A. Real estate professionals (including agents and brokers), and anyone conducting any activities in
                        connection with their application to, or membership of, The Bond (including real estate introduction or
                        referral services) that constitute, or may reasonably be considered by BlackBrick to constitute,
                        professional real estate services of any kind in the UAE, are excluded from membership of The Bond. No
                        Referral Fees (see below) shall be accrued or payable to anyone falling within the category of persons
                        excluded by this Term 2.A.
                    </p>
                    <p>
                        B. If at any time during the application process or your membership, you fall within the categories of
                        excluded persons set out at Term 2.A.
                        – you must immediately (i) notify BlackBrick via the following email
                        address: be@blackbrickproperty.com , and (ii) cancel your application or membership. No Referral Fees
                        (see below) will be payable to you under this agreement once you fall within the categories of excluded
                        persons set out at Term 2.A.
                    </p>
                </section>
                <section>
                    <h2>3. Use of Your Membership</h2>
                    <p>
                        A. You shall not use your membership for any purpose that is unlawful in any way or in breach of these
                        Membership Terms and Conditions. You agree to abide by all laws in force in the United Arab Emirates
                        from time to time during your membership. You shall not procure the breach by other members of their
                        Membership Terms and Conditions, or by any third parties of the law.
                    </p>
                    <p>
                        B. You agree that you shall not:
                        a. Represent that you are an employee of, agent of, or representative of BlackBrick, or in any
                        way commercially connected with it;
                        b. Behave in any way or publish any statement (whether verbally or in writing and via any media)
                        that may in BlackBrick’s view damage the integrity or reputation of BlackBrick;
                        c. Use your membership for the purposes of collecting sensitive commercial information to be
                        used by you or anyone else for commercial purposes;
                        d. Use any material or information belonging to BlackBrick or otherwise relating to The Bond or
                        your membership, in any manner that infringes any intellectual property right or other
                        proprietary right of any party;
                        e. Restrict or inhibit any other user from using and enjoying their membership;
                        f. Collect, store, or disclose information (including contact details such as email addresses,
                        phone numbers, or social media profiles) about other members, applicants, or anyone
                        connected with BlackBrick or The Bond, without the authorization or consent of those
                        individuals; or
                        g. Create a false identity for the purpose of misleading others.
                    </p>
                </section>
                <section>
                    <h2>4. Membership Benefits</h2>
                    <p>
                        A. Membership of The Bond provides members with access to the following services:
                        a. The Bond events, including (i) Professional/Business events (“Unplugged”) – professional
                        development and empowerment forums, and (ii) Social Events (“Off The Clock”) – a social
                        networking programme, and (iii) Health & Fitness (“Wellness”) – events promoting a healthy
                        lifestyle. Access is restricted to the member only, and no guests shall be permitted without
                        the prior consent of BlackBrick; and
                        b. The Referral Fee Scheme as further set out at Term 5, below.
                    </p>
                    <p>
                        B. For the avoidance of doubt, BlackBrick makes no representation or warranty as to: (i) the frequency,
                        location, quality, duration, quality, or attendance of Unplugged, Off The Clock or Wellness events, or (ii)
                        the uptime, quality, accessibility, or contents of any of The Bond’s or BlackBrick’s social media pages,
                        apps (including The Bond App), or websites.
                    </p>
                </section>
                <section>
                    <h2>5. Referral Fee Scheme</h2>
                    <p>
                        A. Members of The Bond are invited to refer to BlackBrick, via The Bond App or as otherwise directed by
                        BlackBrick, leads of individuals seeking to buy, sell, rent, or lease property in the UAE. If these leads meet
                        the following criteria, they will be considered a Referral for the purposes of these Membership Terms
                        and Conditions:
                    </p>
                    <ul style={{marginLeft:"20px"}}>
                        <li>a. The Referral has been made by a member via The Bond App;</li>
                        <li>b. The proposed Referral has not been in contact with or referred to BlackBrick within one (1)
                        month prior to the member submitting the Referral to BlackBrick;</li>
                        <li>c. The proposed Referral is not actively in discussions, negotiation or communication with
                        BlackBrick as identified on BlackBrick’s CRM (customer relationship management system) as
                        at the time of the member submitting the Referral.</li>
                    </ul>
                    <p>
                        d. The proposed Lead’s full name, phone number, and email address have been given and the
                        proposed Referral has consented to the same;
                    </p>
                    <ul style={{marginLeft:"20px"}}>
                        <li>e. The full address of any property to be sold or rented by the proposed Referral has been
                        provided, along with any other details required by The Bond App; and</li>
                        <li>f. In relation to proposed Referrals who are buyers or renters, the full details of the area sought,
                        type of property sought, and budget available (if known).</li>
                    </ul>
                    <p>
                        If any of these criteria are not met, the individual referred may not be accepted as a Referral.
                    </p>
                    <p>
                        B. Any Referrals presented will be allocated to a relevant individual at BlackBrick (the “Advisor”) to
                        represent that Referral. The Advisor may contact the referring member to discuss the Referral within a
                        reasonable time after the Referral has been presented. BlackBrick may change the Advisor at any time
                        upon immediate notice to you.
                    </p>
                    <p>
                        C. If any Referral results in a successful property transaction for BlackBrick, via the Advisor, on behalf of
                        that Referral within six (6) months of referral, the referring member shall be entitled to a Referral Fee of
                        up to 10% of the Advisor’s net commission from that transaction. BlackBrick shall notify you of the
                        Referral Fee becoming payable.
                    </p>
                    <p>
                        D. BlackBrick reserves the right to change the % payable as a Referral Fee for any future Referral Fee (ie
                        those that are not already accrued and properly payable to the referring member) from time to time
                        immediately upon written notice to you. Your continued use of your membership constitutes consent to
                        any changes made to the Referral Fees.
                    </p>
                    <p>
                        E. A Referral Fee for an individual Referral shall only be payable on transactions within the first 6 months
                        of referral, but there is no cap on the number of Referral Fees that may be paid to the referring member
                        during that time.
                    </p>
                    <p>
                        F. In order for any Referral Fee to be paid to you, you must provide BlackBrick, via The Bond App or direct
                        to the BlackBrick finance team, with valid, legally compliant, bank details for an account based in the
                        UAE. You must also provide BlackBrick with any identification documentation reasonably requested. If
                        you fail to provide BlackBrick with the same within 2 months of BlackBrick’s notification of the Referral
                        Fee becoming payable, you forfeit the Referral Fee. You acknowledge that a delay of this duration would
                        put undue operational and accounting strain on BlackBrick.
                    </p>
                    <p>
                        G. No Referral Fee shall be payable to you if it accrues when you are in breach of these Membership Terms
                        and Conditions, if it is fraudulent, or if it is otherwise in breach of the laws of the United Arab Emirates
                        in force at the time of accrual.
                    </p>
                </section>
                <section>
                    <h2>6. Termination</h2>
                    <p>
                        A. You may terminate your membership at any time via The Bond App. We may terminate your membership
                        at any time in our sole discretion and for any or no reason.
                    </p>
                    <p>
                        B. Upon termination, any accrued Referral Fee properly payable to you, that is not otherwise in breach of
                        these Membership Terms and Conditions, shall be paid to you in accordance with these Membership
                        Terms and Conditions.
                    </p>
                </section>
                <section>
                    <h2>7. Confidential information</h2>
                    <p>
                        A. You undertake that you shall disclose to any person any confidential information concerning the
                        business, affairs, customers, clients or suppliers of BlackBrick or any other members of The Bond, unless
                        required by law, to a court of competent jurisdiction or any governmental or regulatory authority.
                    </p>
                    <p>
                        B. You shall not use any confidential information belonging to BlackBrick or members of the Bond for any
                        purpose other than to exercise your rights and perform your obligations under or in connection with
                        your membership.
                    </p>
                </section>
                <section>
                    <h2>8. Disclaimer</h2>
                    <p>
                        A. To the maximum extent permitted by The Bond and any services provided by BlackBrick are provided “as
                        is”. BlackBrick disclaims all warranties and conditions, whether express, implied or statutory. The entire
                        risk of application, membership, and use thereof rests with you.
                        </p>
                    <p>
                        B. BlackBrick is not responsible for the actions of other members or any other third parties.
                    </p>
                    <p>
                        C. To the maximum extent permitted by law, BlackBrick shall not be liable for any direct, special, incidental,
                        indirect, punitive, reliance, consequential, or other damages whatsoever arising out of or in any way
                        related to The Bond, your application, your membership, any services provided by BlackBrick or third
                        parties, or otherwise under or in connection with any of the same. In no event will the collective liability
                        of BlackBrick exceed the amount of the fees paid to you or owed by you under these Membership Terms
                        and Conditions.
                    </p>
                </section>
                <section>
                    <h2>9. Your Indemnity</h2>
                    <p>
                        You agree to indemnify and hold harmless BlackBrick from and against any and all claims, liabilities,
                        losses, damages, costs, expenses, judgments, fines, and penalties (Claims) including reasonable legal fees
                        resulting from any breach by you of the law or of these Membership Terms and Conditions. You agree
                        that BlackBrick shall have exclusive control over the defense of any Claims.
                    </p>
                </section>
                <section>
                    <h2>10. General</h2>
                    <p>
                        A. These Membership Terms and Conditions may be changed, varied, or revoked at any time and any such
                        change, variation, or revocation shall be deemed to have been brought to your attention and take
                        immediate effect when you are notified in writing (whether by email, via The Bond App, or otherwise).
                    </p>
                    <p>
                        B. No delay or failure by us to exercise any right or remedy under these Membership Terms and Conditions
                        shall operate as a waiver of any such right or remedy. No single or partial exercise by us of any right or
                        remedy under these Membership Terms and Conditions shall prevent any further or other exercise or
                        the exercise of any other right or remedy under these Membership Terms and Conditions, or otherwise.
                    </p>
                    <p>
                        C. If any part of these Membership Terms and Conditions is determined to be invalid, illegal, or
                        unenforceable for any reason, in whole or in part, then it shall be deemed to the minimum extent
                        necessary to make it valid, legal, and enforceable. The remaining parts shall be unaffected and shall
                        remain in full force and effect.
                    </p>
                    <p>
                        D. BlackBrick may assign any of its rights in connection with your membership and/or The Bond, or transfer
                        all of its rights or obligations in relation to the same by novation to any undertaking.
                    </p>
                    <p>
                        E. Nothing in relation to your membership, The Bond, or these Membership Terms and Conditions creates
                        any employment, agency, joint venture, representative or other relationship between you and
                        BlackBrick.
                    </p>
                    <p>
                        F. These Membership Terms and Conditions and any dispute or claim arising out of or in connection with
                        them or their subject matter or formation (including non-contractual disputes or claims), shall be
                        governed by, and construed in accordance with the laws of the United Arab Emirates. Each party
                        irrevocably agrees that the courts of the Dubai International Financial Centre shall have exclusive
                        jurisdiction to settle any dispute or claim arising out of or in connection with the same.
                    </p>
                </section>
                {/* Include other sections similarly */}
            </div>
            <div className="bond-text">
        <div className="container">
          <h3>
            Join The Bond, a networking community
            <br />
            powered by BlackBrick
          </h3>
            <div className="bond-download-the-app">
              <h2>DOWNLOAD THE APP</h2>
              <div className="stores-badges">
                <a
                  href="https://play.google.com/store/apps/details?id=com.bond.tech.bond"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src="/assets/images/google-play-badge.png"
                    alt="Download the app on Google Play"
                  />
                </a>
                <a
                  href="https://apps.apple.com/ae/app/the-bond/id6449622884"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src="/assets/images/App-Store-Badge.png"
                    alt="Download the app on App store"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
            <Footer />
        </>
    );
};

export default TermsAndConditions;
