import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import JournalFilter from "../components/journal/journal-filter";
import BlogCard from "../components/blog-card";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getBlogsFn } from "../context/endpoints";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ErrorMessage from "../components/loading-error/error";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { useInView } from "react-intersection-observer";

const Blogs = () => {
  const { ref, inView } = useInView();
  const [searchParams, setSearchParams] = useSearchParams();
  const Topics = searchParams.get("topic") || "";

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["getBlogsData", Topics],
    queryFn: ({ pageParam = 1 }) => getBlogsFn(Topics, pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.page < lastPage.totalPages) {
        return lastPage.page + 1;
      }
      return undefined;
    },
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const articlesCounter = status === "success" ? data.pages[0]?.totalCount || "0" : "0";

  const loadingSkeleton = Array.from({ length: 8 }).map((_, index) => (
    <Box key={index} sx={{ mb: 2 }}>
      <Skeleton variant="rectangular" width="100%" height={200} />
      <Skeleton variant="text" width="60%" sx={{ mt: 1 }} />
    </Box>
  ));

  return (
    <>
      <Helmet>
        <title>Journal - BlackBrick</title>
        <meta
          name="description"
          content="A selection of ponderings and discoveries from the BlackBrick team"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blackbrickproperty.com/journal" />
        <meta property="og:title" content="Journal - BlackBrick" />
        <meta
          property="og:description"
          content="A selection of ponderings and discoveries from the BlackBrick team"
        />
        <meta property="og:image" content="/logo512.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://blackbrickproperty.com/journal" />
        <meta property="twitter:title" content="Journal - BlackBrick" />
        <meta
          property="twitter:description"
          content="A selection of ponderings and discoveries from the BlackBrick team"
        />
        <meta property="twitter:image" content="/logo512.png" />
      </Helmet>
      <Header />

      <div className="journal-header-container">
        <div className="journal-header-titles">
          <h1>Blogs</h1>
          <h2>
          A curated collection of insights and revelations from the BlackBrick team
          </h2>
        </div>
        {/* <JournalFilter
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          Topics={Topics}
          articlesCounter={articlesCounter}
        /> */}
      </div>
      <div style={{marginTop: "20px"}} className="journal-container container-secondary">
        <div className="journal-article-container">
          {status === "loading" || isFetching ? (
            loadingSkeleton
          ) : status === "error" ? (
            <ErrorMessage>{error.message}</ErrorMessage>
          ) : (
            data.pages.map((group, i) => (
              <React.Fragment key={i}>
                {group.articles?.map((article) => (
                  <BlogCard
                    key={article._id}
                    article={article}
                  />
                ))}
              </React.Fragment>
            ))
          )}
        </div>
        <div ref={ref}></div>
        {isFetchingNextPage && loadingSkeleton}
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
