import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Filter = ({
  collection,
  setCollection,
  location,
  setLocation,
  setAgentID,
  minBedrooms,
  setMinBedrooms,
  maxBedrooms,
  setMaxBedrooms,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  propertiesfound,
  communities,
  Agents,
  AgentID,
  AgentName,
  setAgentName,
  Category,
  setCategory
}) => {
  const ulocation = useLocation();
  const navigate = useNavigate();
  const locationPath = ulocation.pathname.replace("/properties/", "");
  const TypeRef = useRef(locationPath);
  const CollectionRef = useRef(null);
  const LocationRef = useRef(null);
  const AgentRef = useRef(null);
  const MinBedroomsRef = useRef(null);
  const MaxBedroomsRef = useRef(null);
  const MinPriceRef = useRef(null);
  const MaxPriceRef = useRef(null);
  const [isPriceDropdownOpen, setIsPriceDropdownOpen] = useState(false);
  const [isBedroomsDropdownOpen, setIsBedroomsDropdownOpen] = useState(false);
  const [minPriceValue, setMinPriceValue] = useState("");
  const [maxPriceValue, setMaxPriceValue] = useState("");
  const [minBedroomsValue, setMinBedroomsValue] = useState("");
  const [maxBedroomsValue, setMaxBedroomsValue] = useState("");

  const togglePriceDropdown = () => {
    setIsPriceDropdownOpen(!isPriceDropdownOpen);
    if (isBedroomsDropdownOpen) {
      setIsBedroomsDropdownOpen(false);
    }
  };

  const toggleBedroomsDropdown = () => {
    setIsBedroomsDropdownOpen(!isBedroomsDropdownOpen);
    if (isPriceDropdownOpen) {
      setIsPriceDropdownOpen(false);
    }
  };
  const resetFilters = () => {
    setCollection("");
    setCategory("");
    setLocation("");
    setAgentID("");
    setMinBedrooms("");
    setMaxBedrooms("");
    setMinPrice("");
    setMaxPrice("");
    setMinPriceValue("");
    setMaxPriceValue("");
    setMinBedroomsValue("");
    setMaxBedroomsValue("");
    
    if (CollectionRef.current) CollectionRef.current.value = "";
    if (LocationRef.current) LocationRef.current.value = "";
    if (MinBedroomsRef.current) MinBedroomsRef.current.value = "";
    if (MaxBedroomsRef.current) MaxBedroomsRef.current.value = "";
    if (MinPriceRef.current) MinPriceRef.current.value = "";
    if (MaxPriceRef.current) MaxPriceRef.current.value = "";
    if (AgentRef.current) AgentRef.current.value = "";

  };
  
  const PropertyTypeStateChange = (event) => {
    navigate(`/properties/${event.target.value}`);
  };
  const MinPriceStateChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      setMinPrice(""); // Set empty string if input is empty
      setMinPriceValue("");
    } else {
      const formattedPrice = formatPrice(inputValue);
      setMinPrice(formattedPrice.replace(/,/g, ""));
      setMinPriceValue(formattedPrice);
    }
  };
  
  const MaxPriceStateChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      setMaxPrice(""); // Set empty string if input is empty
      setMaxPriceValue("");
    } else {
      const formattedPrice = formatPrice(inputValue);
      setMaxPrice(formattedPrice.replace(/,/g, ""));
      setMaxPriceValue(formattedPrice);
    }
  };
  
  const formatPrice = (price) => {
    // Remove existing commas and parse the value as a float
    const floatValue = parseFloat(price.replace(/,/g, ""));
    // Format the float value with commas and return as string
    return floatValue.toLocaleString(undefined, {maximumFractionDigits: 2});
  };
  
  
  const LocationStateChange = (event) => {
    setLocation(event.target.value);
  };

  const AgentStateChange = (event) => {
    const selectedAgentId = event.target.value;
    setAgentID(selectedAgentId);

    const selectedAgent = Agents.find((agent) => agent?.AgentID === selectedAgentId);

    setAgentName(selectedAgent?.Agent);
  };

  const MinBedroomsStateChange = (event) => {
    setMinBedrooms(event.target.value);
    setMinBedroomsValue(event.target.value);
  };

  const MaxBedroomsStateChange = (event) => {
    const maxBedroomsValue = event.target.value;
    setMaxBedrooms(maxBedroomsValue);
    setMaxBedroomsValue(maxBedroomsValue);

    if (minBedrooms && maxBedroomsValue && parseInt(maxBedroomsValue) < parseInt(minBedrooms)) {
      setMinBedrooms(maxBedroomsValue);
      setMinBedroomsValue(maxBedroomsValue);
    }
  };

  const CollectionStateChange = (event) => {
    setCollection(event.target.value);
  };

  useEffect(() => {
    TypeRef.current.value = ulocation.pathname.replace("/properties/", "");
  }, [ulocation]);

  return (
    <>
      <div className="container">
        <div className="properties-selection-bar">
          <select onChange={PropertyTypeStateChange} ref={TypeRef} id="properties-type">
            <option value="buy">buy</option>
            <option value="rent">rent</option>
          </select>
          <select onChange={CollectionStateChange} ref={CollectionRef} id="properties-collection">
            <option value="" style={{ fontWeight: "600" }}>
              collection
            </option>
            <option value="golf">Golf</option>
            <option value="coastal">Coastal</option>
            <option value="marina">Marina</option>
            <option value="downtown">Downtown</option>
            <option value="community">Community</option>
            <option value="family homes">Family Homes</option>
            <option value="contemporary homes">Contemporary Homes</option>
            <option value="investment">Investment</option>
          </select>
          <select onChange={LocationStateChange} ref={LocationRef} id="properties-location">
            <option value="" style={{ fontWeight: "600" }}>
              location
            </option>
            {communities.length > 0 &&
              communities.map((location, index) => {
                return (
                  <option key={index} value={location}>
                    {location}
                  </option>
                );
              })}
          </select>

          <select onChange={AgentStateChange} ref={AgentRef} id="properties-agent">
            <option value="" style={{ fontWeight: "600" }}>
              Agents{" "}
            </option>
            {Agents?.length > 0 &&
              Agents?.map((obj, index) => {
                return (
                  <option key={index} value={obj.AgentID}>
                    {obj.Agent}
                  </option>
                );
              })}
          </select>
          <div style={{ position: "relative", display: "inline-flex", flexDirection: "column" }}>
            <button id="properties-bedrooms" className="dropdown-toggle" onClick={toggleBedroomsDropdown} style={{ display: "inline-flex", alignItems: "center", fontFamily: "inherit" }}>
              <span style={{ marginRight: "5px" }}>Bedrooms</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                <path d="M6 9l6 6 6-6" />
              </svg>
            </button>
            {isBedroomsDropdownOpen && (
              <div className="dropdown-content" style={{ position: "absolute", top: "100%", left: 0 }}>
                <div style={{ display: "flex", flexDirection: "column", border: "1px solid #ccc", padding: "5px" }}>
                  <select onChange={MinBedroomsStateChange} ref={MinBedroomsRef} id="properties-min-bedrooms" value={minBedroomsValue}>
                    <option value="" style={{ fontWeight: "600" }}>
                      Min
                    </option>
                    {[...Array(10).keys()].map((num) => (
                      <option key={num} value={num + 1}>{`${num + 1} Bed${num !== 0 ? "s" : ""}`}</option>
                    ))}
                  </select>
                  <select onChange={MaxBedroomsStateChange} ref={MaxBedroomsRef} id="properties-max-bedrooms" value={maxBedroomsValue}>
                    <option value="" style={{ fontWeight: "600" }}>
                      Max
                    </option>
                    {[...Array(10).keys()].map((num) => (
                      num + 1 >= minBedroomsValue || !minBedroomsValue ? (
                        <option key={num} value={num + 1}>{`${num + 1} Bed${num !== 0 ? "s" : ""}`}</option>
                      ) : null
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>

          <div style={{ position: "relative", display: "inline-flex", flexDirection: "column" }}>
            <button id="properties-price" className="dropdown-toggle" onClick={togglePriceDropdown} style={{ display: "inline-flex", alignItems: "center", fontFamily: "inherit" }}>
              <span style={{ marginRight: "5px" }}>Price</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                <path d="M6 9l6 6 6-6" />
              </svg>
            </button>
            {isPriceDropdownOpen && (
              <div className="dropdown-content" style={{ position: "absolute", top: "100%", left: 0 }}>
                <div style={{ display: "flex", flexDirection: "column", border: "1px solid #ccc", padding: "5px" }}>
                  <input
                    onChange={MinPriceStateChange}
                    type="text"
                    ref={MinPriceRef}
                    id="properties-min-price"
                    placeholder="Min"
                    style={{ borderBottom: "1px solid #000", marginBottom: "5px" }}
                    value={minPriceValue}
                  />
                  <input
                    onChange={MaxPriceStateChange}
                    type="text"
                    ref={MaxPriceRef}
                    id="properties-max-price"
                    placeholder="Max"
                    style={{ borderBottom: "1px solid #000", marginTop: "5px" }}
                    value={maxPriceValue}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="properties-selection-filtering">
        <div>
          <h3>{propertiesfound} properties found</h3>
          <h4>
            filter by:&nbsp;
            <>{locationPath},&nbsp;</>
            {!!collection && <>Collection: {collection},&nbsp;</>}
            {!!location && <>Location: {location},&nbsp;</>}
            {!!minBedrooms && <>{minBedrooms} Min Bedrooms,&nbsp;</>}
            {!!maxBedrooms && <>{maxBedrooms} Max Bedrooms,&nbsp;</>}
            {!!minPrice && <>Min AED: {Number(minPrice).toLocaleString()},&nbsp;</>}
            {!!maxPrice && <>Max AED: {Number(maxPrice).toLocaleString()},&nbsp;</>}
            {!!AgentID &&  <>Agent Name: {AgentName},&nbsp;</>}
            {!!Category &&  <>Category: {Category},&nbsp;</>}

          </h4>
        </div>
        <div>
          <h3 onClick={resetFilters}>
            <span>x</span>clear filters
          </h3>
        </div>
      </div>
    </>
  );
};

export default Filter;
